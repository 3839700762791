import deps from "../depsloader"

export default () => {
  let stateCheck = setInterval(() => {
    if (document.readyState === 'complete') {
      clearInterval(stateCheck);
      deps.load("scrollnav").then((scrollNavigation) => {
        const scr = new scrollNavigation({
          animationDuration: 233,
          activeClass: 'active',
          linksSelector: '#menu-hauptnavigation .menu-item > a',
          offset: -50
        });

        scr.start();
      })
    }
  }, 100);
}
