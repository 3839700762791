<template>
<v-app>
<v-dialog
    v-model="visible"
    width="400"
  >
  <v-card>
    <v-card-title
      class="headline bg-primary w-100"
      primary-title
    >
        Kundenstimme abgeben
        <v-spacer></v-spacer>
        <div
          class="float-right"><v-btn
          icon
          dark
          @click="visible = false">
          <span class="fal fa-2x fa-times"></span>
        </v-btn>
        </div>
    </v-card-title>

    <v-card-text>
      <contact-form :name="name" buttonText="Bewertung abgeben" :requiredFields="requiredFields">
          <testimonial-form></testimonial-form>
          <template slot="disclaimer">
            Mit der Erfassung, Speicherung, Verwendung und Veröffentlichung meiner Bewertung bin ich einverstanden.
            Hinweis: Sie können Ihre Einwilligung jederzeit widerrufen.
          </template>
      </contact-form>
    </v-card-text>
  </v-card>
</v-dialog>
</v-app>
</template>

<script>
import ContactForm from "./ContactForm.vue";
import TestimonialForm from "./TestimonialForm.vue";

export default {
  name: "TestimonialApp",

  components: {
    ContactForm,
    TestimonialForm
  },

  data() {
    return {
      name: "testimonials",
      visible: false
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    }
  },

  methods: {
  },

  mounted() {
    const openButton = document.querySelector("a[href='#bewerten']");
    if (openButton) {
      openButton.addEventListener("click", (e) => {
        e.preventDefault();

        this.visible = true;
      });
    }
  }
};
</script>
