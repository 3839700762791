import Vue from "vue";
import vuetify from "./vuetify.js";
import deps from "./deps";
import yeti from "./yeti";
import MainCss from "./MainCss";

import "./sass/fontawesome/fas.font.js"
import "./sass/fontawesome/far.font.js"
import "./sass/fontawesome/fal.font.js"
import "./sass/fontawesome/fab.font.js"
import "./sass/fontawesome/fontawesome.scss";

import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

Vue.config.productionTip = false;

yeti.init_readmore = function() {
  const readmores = document.querySelectorAll(".readmorejs.loading");
  if (readmores) {
    readmores.forEach((el) => {
      var clampElement = el.querySelector(".readmore__wrap");
      var toggleElement = el.querySelector(".readmore__state");
      var buttonElement = el.querySelector(".readmore__trigger");
      var elements = clampElement.children;

      toggleElement.onchange = function() {
        let isClamped = !this.checked;
        clampElement.classList.remove("loading");
        clampElement.style.overflow = "";
        clampElement.style.height = "auto";
        clampElement.style.maxHeight = "none";
        for (let i = 0; i < elements.length; i++) {
          let child = elements[i];
          if (child.style.removeProperty) {
            child.style.removeProperty('webkitLineClamp');
            child.style.removeProperty('display');
          } else {
            child.style.removeAttribute('webkitLineClamp');
            child.style.removeAttribute('display');
          }
        }

        if (isClamped) {
          clampElement.style.overflow = "hidden";
          if (clampElement.style.removeProperty) {
            clampElement.style.removeProperty('height');
            clampElement.style.removeProperty('max-height');
          } else {
            clampElement.style.removeAttribute('height');
            clampElement.style.removeAttribute('max-height');
          }

          let clampHeight = clampElement.offsetHeight;
          let clampOffset = clampElement.offsetTop;
          let truncated = false;

          for (let i = 0; i < elements.length; i++) {
            let child = elements[i];
            let parentOffset = child.offsetTop - clampOffset;
            let childHeight = child.offsetHeight;
            if (parentOffset + childHeight > clampHeight) {
              if (!truncated) {
                truncated = true;
                //hier ist ende
                let lineHeight = parseInt(getComputedStyle(child).lineHeight.replace("px", ""));
                let spaceToShow = clampHeight - parentOffset;
                let linesToShow  = Math.floor(spaceToShow / lineHeight);

                let sty = child.style;
                sty.overflow = 'hidden';
                sty.textOverflow = 'ellipsis';
                sty.webkitBoxOrient = 'vertical';
                sty.display = '-webkit-box';
                sty.webkitLineClamp = linesToShow;
              } else {
                child.style.display = "none";
              }
            }
          }

          if (!truncated) {
            buttonElement.style.display = "none";
          } else {
            if (buttonElement.style.removeProperty) {
              buttonElement.style.removeProperty('display');
            } else {
              buttonElement.style.removeAttribute('display');
            }
          }
        }
      }

      toggleElement.onchange();

      window.addEventListener("resize", () => {
        toggleElement.onchange();
      }, false);
    });
  }
}

Vue.prototype.$yeti = yeti;
Vue.prototype.$yeti.setupTracking();

// lädt das CSS in der richtigen Reihenfolge
new Vue({
  vuetify,
  components: {
    MainCss
  }
});

Object.keys(deps.entrypoints).forEach(entrypoint => {
  const fn = require("./entrypoints/" + entrypoint + ".js");
  fn.default();
});

Vue.prototype.$yeti.init_readmore();

const requirePO = require.context('./lang', false, /\.po$/);
requirePO.keys().forEach(requirePO);
